const handleError = async (error, store) => {

    await sleep(5000);

    let ip;

    if (store.getState().loginReducer.customerProps !== undefined){
      const props = store.getState().loginReducer.customerProps;
      ip = props.find(p => p.key === 'IP Address');
    }
  
    let data = {
      recepients: process.env.GATSBY_ERROR_EMAIL_RECEPIENTS,
      message: error.stack,
      shortDesc: error.message,
      host: window.location.host,
      ip: (ip) ? ip.value : 'Unauthorized',
      url: window.location.href,
    };
  
    if (data.host.startsWith('localhost')) {
      return;
    }
  
    const userData = JSON.parse(sessionStorage.getItem('UserData'));
    const userLocation = JSON.parse(localStorage.getItem('userLocation'));
  
    if (userData) {
      data = {
        ...data,
        userEmail: userData.loginname,
      };
    }
  
    if (userLocation) {
      data = {
        ...data,
        userLocation: `${userLocation.postal} ${userLocation.city} ${userLocation.regionname} ${userLocation.countryname}`,
        languages: userLocation.languages,
      };
    }
  
    const dataString = JSON.stringify(data);
  
    //console.info("data_error", dataString);
  
    fetch(
      'https://script.google.com/macros/s/AKfycbw0YrHCVfW2eGukhywHa-edKECN9Q4Y7L50cuvc-sCMvW5jAPqz/exec',
      {
        method: 'POST',
        mode: 'no-cors',
        headers: { 'Content-Type': 'application/json' },
        body: dataString,
      }
    )
      .then((response) => {
        console.info('response', response);
      })
      .catch((error) => {
        console.info('error', error);
      });
  
    return true;
  };

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  
  export default handleError;
  