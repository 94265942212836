/* Copyright 2020 Avetti.com Corporation - All Rights Reserved

This source file is subject to the Avetti Commerce Front End License (ACFEL 1.20)
that is accessible at https://www.avetticommerce.com/license */
import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, withPrefix } from "gatsby";

import { Provider, shallowEqual, useSelector } from "react-redux";
import { I18nContextProvider } from "../i18n/index";
import { IntlProvider } from "react-intl";

import configureStore from "../redux/index.js";

import "../assets/css/menu.css";
import "../assets/css/minified.css";
import "../assets/css/avetti.css";
import "../assets/css/mailchimp.css";

import "../assets/css/sl.css";
import "../assets/css/b2b2c.css";
import "../assets/css/fontello.css";
// import "../assets/jss/demo-ar.scss";
import "../assets/css/purgeignore.css";

import handleError from "../functions/handleError";
import LayoutWrapper from "./LayoutWrapper.js";

export const store = configureStore();

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query MenuQuery {
      allAvettiMenu {
        nodes {
          childs {
            cid
            name
            URL
            description
          }
        }
      }
      allAvettiCategory(filter: { url: { in: "sellers" } }) {
        nodes {
          name

          items {
            id
            title
            code
            desc
          }
        }
      }
    }
  `);

  if (typeof window !== "undefined") {
    window.addEventListener("error", function (event) {
      if (event.error.hasBeenCaught !== undefined) {
        return false;
      }
      event.error.hasBeenCaught = true;
      handleError(event.error, store);
    });
  }

  return (
    <I18nContextProvider>
      <IntlProvider locale={"en"}>
        <Provider store={store}>
          <LayoutWrapper children={children} data={data} />
        </Provider>
      </IntlProvider>
    </I18nContextProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
