/* Copyright 2020 Avetti.com Corporation - All Rights Reserved

This source file is subject to the Avetti Commerce Front End License (ACFEL 1.20)
that is accessible at https://www.avetticommerce.com/license */
import {
  FETCH_WISHLIST_SUCCESS,
  TOGGLE_WISHLIST_SUCCESS,
  WISHLIST_ADD_REDUX,
  FETCH_WISHLIST
} from "../types.js";
import {
  WISHLIST_LINK,
  WISHLIST_ADD_LINK,
  WISHLIST_REMOVE_LINK
} from "../links.js";
import { LINK_DISTRIBUTION } from "../../project-config.js";

export const fetchWishList = () => async dispatch => {
  const data = await fetch(
    `${LINK_DISTRIBUTION}/wishlistservice.ajx?vid=20180521148&action=get&p=1`
  );
  let wishlist = await data.json();

  dispatch({ type: FETCH_WISHLIST, payload: wishlist });
};

export const addFunctionWishList = wishlist => ({
  type: WISHLIST_ADD_REDUX,
  payload: wishlist
});

export const toggleWishListAction = (
  id,
  code,
  title,
  desc,
  currency_sign,
  image,
  price,
  url,
  wishListState
) => {
  return dispatch => {
    id = String(id);
    let wishListTemp = wishListState || [];
    const date = Date.now();
    if (wishListTemp.length < 1 || !wishListTemp.some(w => w.id == id)) {
      dispatch(
        addFunctionWishList([
          ...wishListTemp,
          { id, code, title, desc, currency_sign, image, price, url, date }
        ])
      );
    } else {
      let tempWishList = wishListTemp.filter(w => w.id != id);
      dispatch(addFunctionWishList([...tempWishList]));
    }
  };
};

export const addAuctionItemToWishList = (
  id,
  code,
  title,
  desc,
  currency_sign,
  image,
  price,
  url,
  wishListState
) => {
  return dispatch => {
    id = String(id);
    let wishListTemp = wishListState || [];
    const date = Date.now();

    wishListTemp = wishListTemp.filter(w => w.id != id); // remove already added product when they were added by bid button event
    dispatch(
      addFunctionWishList([
        ...wishListTemp,
        { id, code, title, desc, currency_sign, image, price, url, date }
      ])
    );
  };
};
