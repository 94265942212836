import React from "react";
import Authenticate from "./Authenticate";

import MenuTopBar from "../header/MenuTopBar";
import Compare from "../header/Compare";
import PromotionComponent from "../components/AC-Advertising/PromotionComponent";

import Common from "../shared/components/Common";
import Footer from "../components/AC-Footer/Footer";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

export default function LayoutWrapper({ data, children }) {
  const dispatch = useDispatch();

  const authenticatedState = useSelector(
    state => state.mainReducer.authenticated,
    shallowEqual
  );

  return (
    <>
      {!authenticatedState && !process.env.GATSBY_IS_PUBLISHED  ? (
        <Authenticate dispatch={dispatch} />
      ) : (
        <>
          <Common />
          <div>
            <MenuTopBar data={data.allAvettiMenu.nodes[0].childs} brands={[]} />
            {/* <LocationBar /> */}
            <PromotionComponent />
            <Compare />
          </div>
          {children}
          {/* <MailchimpSub /> */}
          <Footer />
        </>
      )}
    </>
  );
}
