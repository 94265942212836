import * as types from "../types";

const initialState = {
  bidHistory: [],
  auctionFetched: false
};

const copyInitialState = { ...initialState };

const auctionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.RESET_AUCTION_STATE: {
      return { ...copyInitialState };
    }
    case types.UNMOUNT_PRODUCT_PAGE: {
      return { ...copyInitialState };
    }
    case types.FETCH_PRODUCT_BID_HISTORY: {
      let { histories, auction, highestBid } = payload;
      /*
          with this sort result, largest bid is always at the end of the array.
        */
      auction = { ...auction, highestBid };

      // console.log("---------------- SORTING DONE ----------------");

      return {
        ...state,
        bidHistory: histories,
        ...auction,
        auctionFetched: true
      };
    }
    default:
      return state;
  }
};

export default auctionReducer;
