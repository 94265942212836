import React, { useState } from "react";
import TypoGraphy from "../AC-UI-Elements/TypoGraphy/TypoGraphy";
import Dialog from "../AC-UI-Elements/Dialog/Dialog";
import DialogContent from "../AC-UI-Elements/DialogContent/DialogContent";

const Notification = () => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div id="customized-dialog-title" onClose={handleClose}>
          We'll be back shortly!
        </div>
        <DialogContent dividers>
          <TypoGraphy gutterBottom>
            {/*Sorry! We are restarting the system to add more features and capabilities. Come back in 5 to 10 minutes...*/}
            Sorry for the inconvenience! There is a misconfiguration of this
            product that we are correcting or we're performing some maintenance
            on the system. Come back in 5 to 10 minutes and please refresh the
            page.
          </TypoGraphy>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Notification;
